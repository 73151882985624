// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://bfvfslvncpqeykbegztq.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJmdmZzbHZuY3BxZXlrYmVnenRxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzY2OTk4OTQsImV4cCI6MjA1MjI3NTg5NH0.fA0qLbLcUhSg_tWRq7Ow5ckghyQMHcnJusVzJT7ddRA";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);