import { motion } from "framer-motion";

export const Counter = ({ count = 0 }) => {
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      className="text-center"
    >
      <h2 className="text-2xl font-semibold mb-4">The Big Reveal!</h2>
      <div className="text-7xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-pink-500">
        {count.toLocaleString()}
      </div>
      <p className="mt-4 text-xl text-gray-600">
        people have paid £1 to see this number
      </p>
    </motion.div>
  );
};