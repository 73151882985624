import { Button } from "@/components/ui/button";
import { PoundSterling } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

export const PaymentButton = () => {
  const handlePayment = async () => {
    try {
      const { data, error } = await supabase.functions.invoke('create-checkout', {
        method: 'POST',
      });

      if (error) throw error;
      if (!data?.url) throw new Error('No checkout URL received');

      // Redirect to Stripe Checkout
      window.location.href = data.url;
    } catch (error) {
      console.error('Payment error:', error);
      toast.error('Failed to initiate payment. Please try again.');
    }
  };

  return (
    <Button
      size="lg"
      className="group relative overflow-hidden bg-gradient-to-br from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all duration-300 animate-bounce-slow"
      onClick={handlePayment}
    >
      <span className="relative z-10 flex items-center gap-2">
        <PoundSterling className="w-5 h-5" />
        Pay £1 to Find Out
      </span>
      <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
    </Button>
  );
};