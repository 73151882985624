import { PaymentButton } from "@/components/PaymentButton";
import { Counter } from "@/components/Counter";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import { Share2 } from "lucide-react";
import { Button } from "@/components/ui/button";

const Index = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [hasAccess, setHasAccess] = useState(false);
  const [hasExpired, setHasExpired] = useState(false);
  const [isCheckingPayment, setIsCheckingPayment] = useState(false);

  const getCompletedPaymentsCount = async () => {
    const { count, error } = await supabase
      .from('payments')
      .select('*', { count: 'exact' })
      .eq('status', 'completed');

    if (error) throw error;
    return count || 0;
  };

  const { data: count = 0, refetch } = useQuery({
    queryKey: ['payments-count'],
    queryFn: getCompletedPaymentsCount,
    enabled: true,
  });

  const handleShare = async () => {
    const baseUrl = window.location.origin;
    try {
      await navigator.clipboard.writeText(baseUrl);
      toast.success('URL copied to clipboard!');
    } catch (error) {
      console.error('Error copying:', error);
      toast.error('Failed to copy URL. Try copying manually.');
    }
  };

  const checkPaymentStatus = async (sessionId: string) => {
    try {
      setIsCheckingPayment(true);
      
      const { data: paymentData, error } = await supabase
        .from('payments')
        .select('status, created_at')
        .eq('session_id', sessionId)
        .eq('status', 'completed')
        .maybeSingle();

      if (error) throw error;
      
      if (paymentData) {
        const createdAt = new Date(paymentData.created_at);
        const now = new Date();
        const isExpired = (now.getTime() - createdAt.getTime()) / 1000 > 300;

        if (isExpired) {
          setHasExpired(true);
          toast.error('Access to the number only lasts 5 minutes, please pay again.');
        } else {
          setHasAccess(true);
          await refetch(); 
          toast.success('Payment successful! Welcome to the club! 🎉');
        }
      } else {
        toast.error('Payment verification failed. Please refresh the page if you completed the payment.');
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
      toast.error('Error checking payment status. Please try again.');
    } finally {
      setIsCheckingPayment(false);
    }
  };

  useEffect(() => {
    const channel = supabase
      .channel('payments-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'payments'
        },
        () => {
          refetch();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [refetch]);

  useEffect(() => {
    if (sessionId) {
      checkPaymentStatus(sessionId);
    }
  }, [sessionId]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-pink-50">
      <div className="container max-w-3xl mx-auto px-4 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            How Many People Paid £1 to See How Many People Paid £1?
          </h1>
          {!hasAccess && (
            <p className="text-xl text-gray-600 mb-12">
              It's simple: pay £1 to see how many other curious souls have done exactly the same thing.
            </p>
          )}
          <div className="space-y-12">
            {isCheckingPayment ? (
              <div className="flex flex-col items-center gap-4">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
                <p className="text-gray-600">Verifying your payment...</p>
              </div>
            ) : hasAccess ? (
              <div className="space-y-8">
                <Counter count={count} />
                <div className="flex justify-center">
                  <Button
                    onClick={handleShare}
                    variant="outline"
                    className="flex items-center gap-2"
                  >
                    <Share2 className="w-4 h-4" />
                    Share
                  </Button>
                </div>
              </div>
            ) : hasExpired ? (
              <div className="flex flex-col items-center gap-8">
                <PaymentButton />
                <div className="space-y-4">
                  <p className="text-sm text-gray-500 max-w-md">
                    Access expires after 5 minutes, to access the number, you must pay again! 😄
                  </p>
                  <div className="flex justify-center">
                    <Button
                      onClick={handleShare}
                      variant="outline"
                      className="flex items-center gap-2"
                    >
                      <Share2 className="w-4 h-4" />
                      Share
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-8">
                <PaymentButton />
                <p className="text-sm text-gray-500 max-w-md">
                  * Yes, this is real. Yes, this is silly. That's kind of the point! 😄
                </p>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Index;